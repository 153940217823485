import {
    Vue, Options
  } from 'vue-class-component'
  import Header from '@/components/Header.vue'
  import swal from 'sweetalert'
  import { billTypeFactory } from '@/http/bill-type'
  
  @Options({
    components: {
      Header
    },
    data() {
      return {
        billType: [],
        billTypeActive: [],
        billTypeNonActive: [],
        totalBill: 0,
        data: {
          id: '',
          name: '',
          description: '',
          status: true
        },
        showModal: false,
        state: ''
      }
    },
    methods: {
      save: function() {
        if (this.data.name == '') {
          swal("Nama tipe tagihan tidak boleh kosong", "", "warning")
          return
        }
        if (this.state == 'add') {
          this.data.status = (this.data.status) ? 1 : 0,
          Promise.all([
            billTypeFactory.createBillType(this.data),
          ]).then(results => {
            if (results[0] != false) {
              swal("Sukses menyimpan tipe tagihan", "", "success")
              this.showModal = false
              this.reload()
            } else {
              swal("Gagal menyimpan tipe tagihan", "", "error")
            }
          }).catch((e) => {
            swal("Gagal menyimpan tipe tagihan", "", "error")
            console.log(e)
          })
        } else {
          var data = {
            name: this.data.name,
            description: this.data.description,
            status: (this.data.status) ? 1 : 0
          }
          Promise.all([
            billTypeFactory.updateBillType(this.data.id, data),
          ]).then(results => {
            if (results[0] != false) {
              swal("Sukses menyimpan tipe tagihan", "", "success")
              this.showModal = false
              this.reload()
            } else {
              swal("Gagal menyimpan tipe tagihan", "", "error")
            }
          }).catch((e) => {
            swal("Gagal menyimpan tipe tagihan", "", "error")
            console.log(e)
          })
        }
      },
      edit: function(index: any, type: any) {
        // open modal with selected data
        this.showModal = true
        if (type == 'active') {
          var billType = this.billTypeActive[index]
        } else {
          var billType = this.billTypeNonActive[index]
        }
        var data = {
          id: billType.id,
          name: billType.name,
          description: billType.description,
          status: (billType.status == 1) ? true : false,
        }
        this.data = data
        this.state = 'edit'
      },
      add: function() {
        this.showModal = true
        this.data.id = ''
        this.data.name = ''
        this.data.description = ''
        this.data.status = true
        this.state = 'add'
      },
      reload: function() {
        Promise.all([
          billTypeFactory.billType(),
        ]).then(results => {
          this.billType = results[0].data.bill_types
          this.billTypeActive = []
          this.billTypeNonActive = []
          this.billType.forEach((types:any) => {
            if (types.status == 1) {
              this.billTypeActive.push(types)
            } else {
              this.billTypeNonActive.push(types)
            }
          })
        })
      },
      generateStringID: function() {
        var name = this.data.name
        var string_id = name.replace(/ /g,"_")
        this.data.id = string_id.toLowerCase()
      }
    },
    async mounted () {
      await Promise.all([
        billTypeFactory.billType(),
      ]).then(results => {
        this.billType = results[0].data.bill_types
        this.billType.forEach((types:any) => {
          if (types.status == 1) {
            this.billTypeActive.push(types)
          } else {
            this.billTypeNonActive.push(types)
          }
        })
      })
    },
  })
  
  export default class BillType extends Vue {}
  