import {
    Vue, Options
  } from 'vue-class-component'
  import Header from '@/components/Header.vue'
  import swal from 'sweetalert'
  import { billDetailTypeFactory } from '@/http/bill-detail-type'
import { masterDataFactory } from '@/http/master-data'

  @Options({
    components: {
      Header
    },
    data() {
      return {
        billDetailType: [],
        totalBill: 0,
        data: {
          id: '',
          name: '',
          operation: '',
          acc_journal_map_string_id: '',
          description: '',
        },
        showModal: false,
        state: '',
        data_master: {
          math_operations: "1"
        },
        math_operations: null
      }
    },
    methods: {
      save: function() {
        if (this.data.name == '') {
          swal("Nama tipe rincian tagihan tidak boleh kosong", "", "warning")
          return
        }
        if (this.state == 'add') {
          this.data.status = (this.data.status) ? 1 : 0,
          Promise.all([
            billDetailTypeFactory.create(this.data),
          ]).then(results => {
            if (results[0] != false) {
              swal("Sukses menyimpan tipe rincian tagihan", "", "success")
              this.showModal = false
              this.reload()
            } else {
              swal("Gagal menyimpan tipe rincian tagihan", "", "error")
            }
          }).catch((e) => {
            swal("Gagal menyimpan tipe rincian tagihan", "", "error")
            console.log(e)
          })
        } else {
          var data = {
            name: this.data.name,
            operation: this.data.operation,
            acc_journal_map_string_id: this.data.acc_journal_map_string_id,
            description: this.data.description,
          }
          Promise.all([
            billDetailTypeFactory.update(this.data.id, data),
          ]).then(results => {
            if (results[0] != false) {
              swal("Sukses menyimpan tipe rincian tagihan", "", "success")
              this.showModal = false
              this.reload()
            } else {
              swal("Gagal menyimpan tipe rincian tagihan", "", "error")
            }
          }).catch((e) => {
            swal("Gagal menyimpan tipe rincian tagihan", "", "error")
            console.log(e)
          })
        }
      },
      edit: function(index: any, type: any) {
        // open modal with selected data
        this.showModal = true
        var billDetailType = this.billDetailType[index]

        var data = {
          id: billDetailType.id,
          name: billDetailType.name,
          operation: billDetailType.operation,
          acc_journal_map_string_id: billDetailType.acc_journal_map_string_id,
          description: billDetailType.description,
        }
        this.data = data
        this.state = 'edit'
      },
      add: function() {
        this.showModal = true
        this.data.id = ''
        this.data.name = ''
        this.data.description = ''
        this.data.status = true
        this.state = 'add'
      },
      reload: function() {
        Promise.all([
          billDetailTypeFactory.get(),
        ]).then(results => {
          this.billDetailType = results[0].data.bill_detail_types
        })
      },
      loadDataMaster: function() {
        Promise.resolve(masterDataFactory.master_datas(this.data_master))
        .then(result => {
          this.math_operations = result.data.math_operations
        })
      },
    },
    async mounted () {
      await this.loadDataMaster()

      await Promise.all([
        billDetailTypeFactory.get(),
      ]).then(results => {
        this.billDetailType = results[0].data.bill_detail_types
      })
    },
  })

  export default class billDetailType extends Vue {}
