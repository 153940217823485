<template>
  <Header title="Tipe Tagihan" link1="Master Tagihan" link2="Tipe Tagihan"></Header>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <router-link tag="a" to="/bill" class="btn btn-light rounded-pill"> <ion-icon name="arrow-back-outline"></ion-icon> </router-link>
              <h5 class="text-secondary d-inline">Master Tagihan</h5>
            </div>
            <div class="col-md-8">
              <div class="float-end">
              <button class="btn btn-circle btn-gradient" @click="add()"> <ion-icon name="add-circle-outline"></ion-icon> Tambah Tipe Tagihan</button>
                <!-- <div class="d-inline">
                  <label for="">Tahun Ajar:</label>
                  <select class="d-inline text-primary form-select form-select-noborder" aria-label="Default select example">
                    <option value="2">2020/2021</option>
                  </select>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-borderless table-data fw-bold">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Tipe</th>
                <th>Deskripsi</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bill, index) in billTypeActive" :key="index">
                <td>{{index+1}}</td>
                <td>{{bill.name}}</td>
                <td>{{bill.description}}</td>
                <td><span v-if="bill.status == 1" class="label label-success fw-bold">Aktif</span></td>
                <td>
                  <a class="btn btn-sm btn-link-gray" href="#" @click="edit(index, 'active')">Edit <ion-icon name="create-outline"></ion-icon></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-borderless table-data fw-bold">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Tipe</th>
                <th>Deskripsi</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bill, index) in billTypeNonActive" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ bill.name }}</td>
                <td>{{ bill.description }}</td>
                <td><span v-if="bill.status == 0" class="label label-danger fw-bold">Tidak Aktif</span></td>
                <td>
                  <a class="btn btn-sm btn-link-gray" href="#" @click="edit(index, 'nonactive')">Edit <ion-icon name="create-outline"></ion-icon></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Tambah Tipe Tagihan</h5>
                <button type="button" class="btn-close" @click="showModal = false"></button>
              </div>
              <div class="modal-body">
                <form class="row g-3">
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Nama Tipe Tagihan</label>
                    <input type="text" class="form-control" v-model="data.name" @change="generateStringID()" required>
                  </div>
                  <div class="col-md-12 mb-3" v-if="state == 'add'">
                    <label for="" class="form-label">String ID</label>
                    <input type="text" class="form-control" v-model="data.id" required readonly>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Keterangan</label>
                    <textarea class="form-control text-secondary" rows="2" v-model="data.description"></textarea>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Status</label>
                    <div class="form-checkbox">
                      <input class="form-check-input" type="checkbox" v-model="data.status">
                      <span> Aktif</span>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" @click="showModal = false"> <ion-icon name="close-outline"></ion-icon> Tutup</button>
                <button type="button" class="btn btn-primary" @click="save()"> <ion-icon name="save-outline"></ion-icon> Simpan</button>
                <!-- <button type="button" class="btn btn-success"> <ion-icon name="color-wand-outline"></ion-icon> Simpan dan Generate</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script language="ts" src="./scripts/bill-type.ts"></script>
