import axios from 'axios'

class BillDetailTypeFactory {

  async get () {
    try {
      let url = '/bill_detail_types'
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async create (data: any) {
    try {
      let url = '/bill_detail_types'
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async update (id: any, data: any) {
    try {
      let url = '/bill_detail_types/'+id
      var response = await axios.put(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

export let billDetailTypeFactory = new BillDetailTypeFactory()
