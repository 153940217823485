<template>
  <Header title="Tipe Rincian Tagihan" link1="Master Tagihan" link2="Tipe Rincian Tagihan"></Header>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <!-- <div class="col-md-4">
              <router-link tag="a" to="/bill" class="btn btn-light rounded-pill"> <ion-icon name="arrow-back-outline"></ion-icon> </router-link>
              <h5 class="text-secondary d-inline">Tipe Rincian Tagihan</h5>
            </div> -->
            <div class="col-md-12">
              <div class="float-end">
              <button class="btn btn-circle btn-gradient" @click="add()"> <ion-icon name="add-circle-outline"></ion-icon> Tambah Tipe Rincian Tagihan</button>
                <!-- <div class="d-inline">
                  <label for="">Tahun Ajar:</label>
                  <select class="d-inline text-primary form-select form-select-noborder" aria-label="Default select example">
                    <option value="2">2020/2021</option>
                  </select>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-borderless table-data fw-bold">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Tipe</th>
                <th>Operasi</th>
                <!-- <th>No Akun</th> -->
                <th>Deskripsi</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(type, index) in billDetailType" :key="index">
                <td>{{index+1}}</td>
                <td>{{type.name}}</td>
                <td>
                  <div v-if="type.operation == '+'" class="btn btn-outline-success rounded-pill no-pointer"><ion-icon name="add-outline"></ion-icon></div>
                  <div  v-if="type.operation == '-'" class="btn btn-outline-danger rounded-pill no-pointer"><ion-icon name="remove-outline"></ion-icon></div>
                </td>
                <!-- <td>{{type.acc_journal_map_string_id}}</td> -->
                <td>{{type.description}}</td>
                <td>
                  <a class="btn btn-sm btn-link-gray" href="#" @click="edit(index, 'active')">Edit <ion-icon name="create-outline"></ion-icon></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-if="state == 'add'" class="modal-title">Tambah Tipe Rincian Tagihan</h5>
                <h5 v-if="state == 'edit'" class="modal-title">Ubah Tipe Rincian Tagihan</h5>
                <button type="button" class="btn-close" @click="showModal = false"></button>
              </div>
              <div class="modal-body">
                <form class="row g-3">
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Nama Tipe Tagihan</label>
                    <input type="text" class="form-control" v-model="data.name" required>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Operasi</label>
                    <select class="form-select" v-model="data.operation">
                      <option
                      v-for="operation in math_operations"
                      :key="operation.id"
                      :label="operation.id"
                      :value="operation.symbol">
                    </option>
                    </select>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Keterangan</label>
                    <textarea class="form-control text-secondary" rows="2" v-model="data.description"></textarea>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="" class="form-label">Pengaturan Jurnal</label>
                    <input type="text" class="form-control" v-model="data.acc_journal_map_string_id" required>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" @click="showModal = false"> <ion-icon name="close-outline"></ion-icon> Tutup</button>
                <button type="button" class="btn btn-primary" @click="save()"> <ion-icon name="save-outline"></ion-icon> Simpan</button>
                <!-- <button type="button" class="btn btn-success"> <ion-icon name="color-wand-outline"></ion-icon> Simpan dan Generate</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script language="ts" src="./scripts/bill-detail-type.ts"></script>
